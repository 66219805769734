<template>
  <div class="page">
      <el-tabs v-if="isSchoolId === 1" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="我创建的筛查计划" name="0"></el-tab-pane>
        <el-tab-pane label="学校上报的筛查计划" name="1"></el-tab-pane>
      </el-tabs>
      <el-alert
        title="学生报表按照学校、年级、班级展示汇总数据，可以切换筛查计划，以图表形式查看、导出。"
        style="border: 1px solid #FA6401;margin-top: 20px;background: rgba(245, 98, 0, 0.05);color: #999999;"
        type="warning"
      ></el-alert>
      <div class="select" style="margin-top: 20px;">
        <div class="header-input" v-if="activeName == 1">
            <el-select v-model="selectSchool" placeholder="请选择学校" size="mini" @change="getScreeningSelectS">
              <el-option
                v-for="item in schoolList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        <el-select v-model="screeningListId" size="mini" filterable placeholder="请选择筛查计划">
          <el-option
            v-for="(item,index) in screeningOptions"
            :key="item.id"
            :label="item.screeningName"
            :value="item.id"
            @click.native="changeScreening(index)"
          ></el-option>
        </el-select>
        <el-select v-if="activeName == 0" v-model="schoolId" size="mini" placeholder="请选择学校" >
          <el-option
            v-for="item in schoolOptions"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          ></el-option>
        </el-select>
<!--        <el-select v-model="gradeId" size="mini" clearable :disabled="true" placeholder="请选择年级" @change="getClassSelect">-->
<!--          <el-option-->
<!--            v-for="item in gradeOptions"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value"-->
<!--          ></el-option>-->
<!--        </el-select>-->
<!--        <el-select v-model="classId" size="mini" clearable :disabled="true" placeholder="请选择班级">-->
<!--          <el-option-->
<!--            v-for="item in classOptions"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value"-->
<!--          ></el-option>-->
<!--        </el-select>-->
      </div>
      <div class="echarts" v-show="screeningListId">
        <div class="first">
          <div class="title">
            <div class="left">
              <h3>筛查计划总人数</h3>
              <h2>{{normalData.totalNums}}</h2>
            </div>
            <div class="right">
              <h3>已筛查人数：</h3>
              <h4>{{normalData.haveScreenedNums}}</h4>
            </div>
          </div>
          <div class="echarts1" v-if="!inResize">
            <div class="myecharts1">
              <div class="chartBox"  style="width: 100%;height: 38vh;max-height: 330px;">
                <div id="myChart1" style="width: 100%;height: 100%"></div>
              </div>
            </div>
            <div class="myecharts1">
              <div class="chartBox"  style="width: 100%;height: 38vh;max-height: 330px;">
                <div id="myChart2" style="width: 100%;height: 100%"></div>
              </div>
            </div>
            <div class="myecharts1">
              <div class="chartBox"  style="width: 100%;height: 38vh;max-height: 330px;">
                <div id="myChart3" style="width: 100%;height: 100%"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="second">
          <div class="myecharts2">
            <div class="chartBox"  style="width: 100%;height: 45vh;max-height: 390px; min-width: 450px;">
              <div id="myChart4" style="width: 100%;height: 100%"></div>
            </div>
          </div>
        </div>
        <div class="fourth">
          <div class="fourthHeader">
            <span class="title">{{ screeningObj.screeningName }} 数据一览表</span>
            <el-link class="fourth-tips" style="color: #333" @click="goTo('/detection/screeningListQuery',{row:screeningObj})">查看更多数据>></el-link>
          </div>
<!--          class="hidden-tbody"-->
          <el-table
            :data="tableData"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{textAlign: 'center'}"
            height="318"
            ref="rw_table"
            style="width: 100%;"
          >
            <el-table-column prop="className" label="班级" width="102">
            </el-table-column>
            <el-table-column prop="classNums" label="班级总人数" :formatter="formNull" style="text-align: center">
            </el-table-column>
            <el-table-column prop="abnormalVisionNums" :formatter="formNull" style="width: 100%" :cell-style="{ textAlign: 'center' }" label="视力不良人数">
            </el-table-column>
            <el-table-column prop="ametropiaNums" :formatter="formNull" label="屈光不良人数">
            </el-table-column>
              <el-table-column prop="abnormalRate" :formatter="formAbnormalRate" label="视力不良率">
            </el-table-column>
            <el-table-column prop="ametropiaRate" :formatter="formAmetropiaRate" label="屈光不良率">
            </el-table-column>
          </el-table>
        </div>
        <div class="fifth">
          <div class="right">
            <div class="title">视力异常详情</div>
            <ul>
              <li><div></div> 轻度近视不良人数：{{degreeData.mildMyopiaNums}}</li>
              <li><div></div> 中度近视不良人数：{{degreeData.moderateMyopiaNums}}</li>
              <li><div></div> 重度近视不良人数：{{degreeData.severeMyopiaNums}}</li>
            </ul>
            <div class="chartBox"  style="width: 100%;height: 35vh">
              <div id="myChart5" style="width: 100%;height: 100%"></div>
            </div>
          </div>
          <div class="left">
             <div  class="title">屈光异常详情</div>
            <ul>
              <li><div></div> 轻度屈光不正人数：{{degreeData.mildRefractiveNums}}</li>
              <li><div></div> 中度屈光不正人数：{{degreeData.moderateRefractiveNums}}</li>
              <li><div></div> 重度屈光不正人数：{{degreeData.severeMRefractiveNums}}</li>
            </ul>
            <div class="chartBox"  style="width: 100%;height: 35vh">
              <div id="myChart6" style="width: 100%;height: 100%"></div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import selectService from '@/globals/service/detection/select.js'
import analysisService from '@/globals/service/detection/analysis.js'
// import screeningService from '@/globals/service/detection/screening.js'
import organizationService from '@/globals/service/management/organization.js'
import storage from '@/globals/storage/index.js'

require('echarts/lib/component/legend')

let rolltimer = '' // 自动滚动的定时任务
export default {
  data () {
    return {
      selectSchool: null,
      schoolList: [],
      isSchoolId: null,
      activeName: 0,
      lockGetdata: false,
      inResize: false,
      jdrwInfo: [],
      topInfo: {},
      wtmxInfo: [],
      // 滚动时间，滚动间距
      rollTime: 8,
      rollPx: 1,
      formData: {
        // 默认的设置
        rollTime: 8,
        rollPx: 1
      },
      changValue: null,
      screeningName: '',
      screeningListId: '',
      screeningOptions: [],
      screeningObj: {},
      schoolId: '',
      gradeId: '',
      classId: '',
      schooldis: false,
      gradedis: false,
      classdis: false,
      schoolOptions: [],
      gradeOptions: [],
      classOptions: [],
      startPageScreening: 1,
      pageSizeScreening: 50,
      normalData: {
        totalNums: null,
        haveScreenedNums: null,
        abnormalVisionNums: null,
        ametropiaNums: null,
        myopiaNums: null,
        myopiaPercentage: null,
        visionPercentage: null,
        ametropiaPercentage: null
      },
      tableData: [],
      tablePageData: [],
      degreeData: {
        mildMyopiaNums: 0,
        moderateMyopiaNums: 0,
        severeMyopiaNums: 1,
        mildRefractiveNums: 0,
        moderateRefractiveNums: 0,
        severeMRefractiveNums: 1
      },
      curveData: [],
      myChart1: {},
      myChart2: {},
      myChart3: {},
      myChart4: {},
      myChart5: {},
      myChart6: {}
    }
  },
  mounted () {
    const that = this
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWinSize)
      if (this.lockGetdata) return
      this.lockGetdata = true
      that.getScreeningSelect()
      this.lockGetdata = false
    })
    // setTimeout(() => {
    //   this.changValue = 1;
    // },0);
    setTimeout(() => {
      this.getWinSize()
    }, 8000)
  },
  created () {
    const schoolId = storage.get('schoolId')
    this.isSchoolId = schoolId ? 0 : 1
    this.getSchoolList()
  },
  updated () {
  },
  beforeDestroy () {
    if (this.myChart1) this.myChart1.dispose(); this.myChart1 = null
    if (this.myChart2) this.myChart2.dispose(); this.myChart2 = null
    if (this.myChart3) this.myChart3.dispose(); this.myChart3 = null
    if (this.myChart4) this.myChart4.dispose(); this.myChart4 = null
    if (this.myChart5) this.myChart5.dispose(); this.myChart5 = null
    if (this.myChart6) this.myChart6.dispose(); this.myChart6 = null
  },
  methods: {
    myChartDispose () {
      if (this.myChart1) this.myChart1.dispose(); this.myChart1 = null
      if (this.myChart2) this.myChart2.dispose(); this.myChart2 = null
      if (this.myChart3) this.myChart3.dispose(); this.myChart3 = null
      if (this.myChart4) this.myChart4.dispose(); this.myChart4 = null
      if (this.myChart5) this.myChart5.dispose(); this.myChart5 = null
      if (this.myChart6) this.myChart6.dispose(); this.myChart6 = null
    },
    getScreeningSelectS () {
      this.schoolId = null
      this.schoolOptions = []
      this.screeningListId = null
      this.getScreeningSelect()
    },
    getSchoolList () {
      let data
      selectService.school({ startPage: 1, pageSize: 1000 }).then(res => {
        // const schoolId = storage.get('schoolId')
        // if (schoolId) {
        //   data = res.data.schoolList.filter(item => item.id === schoolId)
        // } else {
        //   data = res.data.schoolList
        // }
        data = res.data.schoolList
        data = data.map(item => {
          return {
            value: item.id,
            label: item.schoolName
          }
        })
        this.schoolList = data
        if (this.schoolList[0]) {
          this.selectSchool = this.schoolList[0].value
          // this.getListData()
        }
      })
    },
    getSchool () {
      const schoolId = storage.get('schoolId')
      if (!this.screeningListId) return
      this.schoolOptions = []
      this.gradeOptions = []
      this.classOptions = []
      organizationService.getSchoolAndUsers({ id: this.screeningListId }).then(res => {
        const data = []
        res.data.screeningVoList.forEach(item => {
          if (!data.some(items => items.schoolId === item.schoolId)) {
            if (schoolId) {
              if (Number(schoolId) === Number(item.schoolId)) {
                data.push({
                  schoolId: item.schoolId,
                  schoolName: item.schoolName
                })
              }
            } else {
              data.push({
                schoolId: item.schoolId,
                schoolName: item.schoolName
              })
            }
          }
        })
        this.schoolOptions = data
        if (this.schoolOptions[0]) {
          this.schoolId = this.schoolOptions[0].schoolId
          this.getListData()
          // this.getGradeSelect()
        }
      })
    },
    handleClick () {
      // this.beforeDestroy()
      this.selectSchool = null
      this.screeningListId = null
      this.schoolId = null
      this.screeningListId = null
      this.schoolOptions = []
      if (this.activeName === 1) {
        this.myChartDispose()
        return this.getSchoolList()
      }
      this.myChartDispose()
      this.getScreeningSelect()
    },
    getWinSize () {
      if (this.$route.name !== 'AnalysisChart' || this.myChart1.resize()) {
        return window.removeEventListener('resize', this.getWinSize)
      }
      this.myChart1.resize()
      this.myChart2.resize()
      this.myChart3.resize()
      this.myChart4.resize()
      this.myChart5.resize()
      this.myChart6.resize()
    },
    autoRoll (stop) {
      if (rolltimer) {
        clearInterval(rolltimer)
      }
      if (stop) {
        return
      }
      // 拿到表格挂载后的真实DOM
      const table = this.$refs.rw_table
      if (!table) return
      // 拿到表格中承载数据的div元素
      const divData = table.bodyWrapper
      // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果
      rolltimer = setInterval(() => {
        // 元素自增距离顶部像素
        // divData.scrollTop += this.rollPx
        divData.scrollTop += this.rollPx
        // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
        if (divData.clientHeight + divData.scrollTop === divData.scrollHeight) {
          // 重置table距离顶部距离
          divData.scrollTop = 0
        }
      }, this.rollTime * 10)
    },
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    getScreeningSelect () {
      const data = { type: this.activeName, startPage: this.startPageScreening, pageSize: this.pageSizeScreening }
      if (this.selectSchool) data.schoolId = this.selectSchool
      selectService.screening(data).then(res => {
        const data = []
        res.data.list.forEach(item => {
          const zhi = data.some(items => item.id === items.id)
          if (!zhi) {
            data.push(item)
          }
        })
        const screeningOptions = data || []
        if (screeningOptions.length > 0) {
          const row = screeningOptions[0]
          this.screeningObj = row
          this.screeningListId = row.id
          this.screeningName = row.screeningName
          this.getSchool()
          // if(row.schoolId){
          // this.schoolOptions = [{
          //   value: row.schoolId,
          //   label: row.schoolName
          // }]
          //   this.schooldis = true
          //   this.school = row.schoolId;
          // this.schoolId = row.schoolId
          //   this.getGradeSelect(row)
          // }
          // if(row.gradeId){
          //   this.gradeId = row.gradeId;
          //   this.newGradeId = row.gradeId;
          //   this.gradedis = true
          // }
          // if(row.classId){
          //   this.classId = row.classId;
          //   this.schoolClassId = row.classId;
          //   this.classdis = true
          // }
        } else {
          // this.$alert('没有可选的筛查计划，请添加', '提示', {
          //   type: 'info',
          //   center: true,
          //   confirmButtonText: '确定',
          //   callback: action => {
          //     this.$router.push({ path: '/detection/screeningPlanAdd' })
          //   }
          // })
        }
        this.screeningOptions = screeningOptions
      })
    },
    // searchScreening (row) {
    //   screeningService.search({ name: row }).then(res => {
    //     this.screeningOptions = res.data || []
    //   })
    // },
    changeScreening (index) {
      // this.schoolId = ''
      // this.gradeId = null
      // this.classId = null
      // this.schooldis = false
      // this.gradedis = false
      // this.classdis = false
      // this.school = ''
      // this.newGradeId = null
      // this.schoolClassId = null
      const row = this.screeningOptions[index] || null
      this.screeningObj = row
      // this.screeningListId = row.id
      // this.screeningName = row.screeningName
      // if(row.schoolId){
      // this.schoolOptions = [{
      //   value: row.schoolId,
      //   label: row.schoolName
      // }]
      // this.schooldis = true
      // this.school = row.schoolId;
      this.schoolId = row.schoolId
      // this.getGradeSelect(row)
      // }
      // if(row.gradeId){
      //   this.gradeId = row.gradeId;
      //   this.newGradeId = row.gradeId;
      //   this.gradedis = true
      // }
      // if(row.classId){
      //   this.classId = row.classId;
      //   this.schoolClassId = row.classId;
      //   this.classdis = true
      // }
      this.getSchool()
      setTimeout(() => {
        this.inResize = true
        this.getListData()
        this.inResize = false
      }, 3000)
    },
    // getGradeSelect (row) {
    //   if (!this.schoolId) {
    //     return false
    //   }
    //   this.gradeOptions = []
    //   this.classOptions = []
    //   if (row && row.gradeId) {
    //     this.gradeId = row.gradeId
    //     this.gradedis = true
    //   } else {
    //     this.gradeId = null
    //     this.classId = null
    //   }
    //   selectService.grade({ schoolId: this.schoolId + '' }).then(res => {
    //     const gradeList = res.data
    //     const gradeOptions = []
    //     gradeList.forEach(item => {
    //       gradeOptions.push({
    //         value: item.id,
    //         label: item.gradeName
    //       })
    //     })
    //     this.gradeOptions = gradeOptions
    //     this.getClassSelect(row)
    //   })
    // },
    // getClassSelect (row) {
    //   if (!this.gradeId) {
    //     return false
    //   }
    //   this.classOptions = []
    //   if (row && row.classId) {
    //     this.classId = row.classId
    //     this.classdis = true
    //   } else {
    //     this.classId = null
    //   }
    //   selectService.class({ gradeId: this.gradeId + '' }).then(res => {
    //     const classList = res.data
    //     const classOptions = []
    //     classList.forEach(item => {
    //       classOptions.push({
    //         value: item.id,
    //         label: item.className
    //       })
    //     })
    //     this.classOptions = classOptions
    //   })
    // },
    getListData () {
      this.getNormal()
      this.getDegree()
      this.getData()
      this.getCurve()
    },
    getNormal () {
      analysisService.normal({ id: this.screeningListId, schoolId: this.schoolId }).then(res => {
        // console.log('res1', res)
        console.log(res.data)
        this.normalData = res.data
        setTimeout(() => {
          this.drawLine1()
        }, 1000)
      })
    },
    getDegree () {
      analysisService.degree({ id: this.screeningListId, schoolId: this.schoolId }).then(res => {
        this.degreeData = res.data
        this.drawLine5()
        this.drawLine6()
      })
    },

    getData () {
      const that = this
      analysisService.data({ id: that.screeningListId, schoolId: this.schoolId }).then(res => {
        that.tableData = res.data || []
        that.$nextTick(() => {
          if (that.tableData.length >= 5) {
            that.autoRoll()
          } else {
            that.autoRoll(1)
          }
        })
      })
    },
    getCurve () {
      analysisService.curve({ id: this.screeningListId, schoolId: this.schoolId }).then(res => {
        // console.log('res', res)
        this.curveData = res.data
        setTimeout(() => {
          this.drawLine4()
        }, 5000)
      })
    },
    formNull (row, column, cellValue) {
      if (cellValue === '' || cellValue === null) {
        return '-'
      } else {
        return cellValue
      }
    },
    formAbnormalRate (row, column, cellValue) {
      return parseFloat(cellValue * 100).toFixed(0) + '%'
    },
    formAmetropiaRate (row, column, cellValue) {
      return parseFloat(cellValue * 100).toFixed(0) + '%'
    },
    drawLine1 () {
      // 基于准备好的dom，初始化echarts实例
      const normalData = this.normalData
      // console.log('normalData', normalData)
      this.myChart1 = this.$echarts.init(document.getElementById('myChart1'))
      // 绘制图表
      const colorList = ['#FF1B1B', '#FFD012']
      const piedata = [
        {
          name: '近视',
          value: normalData.myopiaNums
        },
        {
          name: '视力正常',
          value: normalData.haveScreenedNums - normalData.myopiaNums
        }
      ]
      const option = {
        title: [
          {
            text: '近视率',
            x: 'center',
            bottom: 10,
            textStyle: {
              fontSize: 15
            }
          }
        ],
        color: colorList,
        tooltip: {},
        legend: {
          orient: 'vertical',
          left: 0,
          selectedMode: false,
          textStyle: {
            align: 'left',
            verticalAlign: 'middle',
            rich: {
              name: {
                fontSize: 10
              },
              value: {
                fontSize: 10
              }
            }
          },
          data: ['近视', '视力正常'],
          formatter: (name) => {
            if (piedata.length) {
              const item = piedata.filter((item) => item.name === name)[0]
              return `{name|${name}：}{value| ${item.value}}人`
            }
          }
        },
        series: [
          {
            name: '近视率分析',
            type: 'pie',
            radius: ['40%', '52%'],
            center: ['50%', '50%'],
            label: {
              normal: {
                show: false,
                position: 'center',
                formatter: (name) => {
                  const value = parseFloat((name.value / normalData.haveScreenedNums * 100).toFixed(2)) || 0
                  // console.log('value', value)
                  return `{value|${value}%}\n{label|${name.name}}`
                },
                rich: {
                  value: {
                    padding: 5,
                    align: 'center',
                    verticalAlign: 'middle',
                    fontSize: 20,
                    fontWeight: 700
                  },
                  label: {
                    align: 'center',
                    verticalAlign: 'middle',
                    fontSize: 16
                  }
                }
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '12'
                }
              }
            },
            labelLine: {
              show: false,
              length: 0,
              length2: 0
            },
            data: piedata
          }
        ]
      }
      this.myChart1.setOption(option)
      this.getDefaultSelected(this.myChart1)
      this.drawLine2()
    },
    getDefaultSelected (myChart) {
      let index = 0
      myChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: 0
      })

      myChart.on('mouseover', (e) => {
        if (e.dataIndex !== index) {
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: index
          })
        }
      })
      myChart.on('mouseout', (e) => {
        index = e.dataIndex
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: e.dataIndex
        })
      })
    },
    drawLine2 () {
      const normalData = this.normalData
      // 基于准备好的dom，初始化echarts实例
      this.myChart2 = this.$echarts.init(document.getElementById('myChart2'))
      // 绘制图表
      // const value = parseFloat((normalData.abnormalVisionNums / normalData.haveScreenedNums * 100).toFixed(2)) || 0
      const colorList = ['#FF0000', '#9DDF00']
      const piedata = [
        {
          name: '视力异常',
          value: normalData.abnormalVisionNums
        }, {
          name: '视力正常',
          value: normalData.haveScreenedNums - normalData.abnormalVisionNums
        }
      ]
      const option = {
        title: [
          {
            text: '视力异常率',
            x: 'center',
            bottom: 10,
            textStyle: {
              fontSize: 15
            }
          }
        ],
        color: colorList,
        tooltip: {},
        legend: {
          orient: 'vertical',
          selectedMode: false,
          left: 0,
          textStyle: {
            align: 'left',
            verticalAlign: 'middle',
            rich: {
              name: {
                fontSize: 10
              },
              value: {
                fontSize: 10
              }
            }
          },
          data: ['视力异常', '视力正常'],
          formatter: (name) => {
            if (piedata.length) {
              const item = piedata.filter((item) => item.name === name)[0]
              return `{name|${name}：}{value| ${item.value}}人`
            }
          }
        },
        series: [
          {
            name: '视力异常率分析',
            type: 'pie',
            radius: ['40%', '52%'],
            center: ['50%', '50%'],
            label: {
              normal: {
                show: false,
                position: 'center',
                formatter: (name) => {
                  const value = parseFloat((name.value / normalData.haveScreenedNums * 100).toFixed(2)) || 0
                  return `{value|${value}%}\n{label|${name.name}}`
                },
                rich: {
                  value: {
                    padding: 5,
                    align: 'center',
                    verticalAlign: 'middle',
                    fontSize: 20,
                    fontWeight: 700
                  },
                  label: {
                    align: 'center',
                    verticalAlign: 'middle',
                    fontSize: 16
                  }
                }
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '12'
                }
              }
            },
            labelLine: {
              show: false,
              length: 0,
              length2: 0
            },
            data: piedata
          }
        ]
      }
      this.myChart2.setOption(option)
      this.getDefaultSelected(this.myChart2)
      this.drawLine3()
    },
    drawLine3 () {
      const normalData = this.normalData
      // 基于准备好的dom，初始化echarts实例
      this.myChart3 = this.$echarts.init(document.getElementById('myChart3'))
      // 绘制图表

      // const value = parseFloat((normalData.ametropiaNums / normalData.haveScreenedNums * 100).toFixed(2)) || 0

      const colorList = ['#FF0000', '#4DA1FF']
      const piedata = [
        {
          name: '屈光异常',
          value: normalData.ametropiaNums
        },
        {
          name: '屈光正常',
          value: normalData.haveScreenedNums - normalData.ametropiaNums
        }
      ]
      const option = {
        title: [
          {
            text: '屈光异常率',
            x: 'center',
            bottom: 10,
            textStyle: {
              fontSize: 15
            }
          }
        ],
        color: colorList,
        tooltip: {
          show: false,
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          selectedMode: false,
          left: 0,
          textStyle: {
            align: 'left',
            verticalAlign: 'middle',
            rich: {
              name: {
                fontSize: 10
              },
              value: {
                fontSize: 10
              }
            }
          },
          data: ['屈光异常', '屈光正常'],
          formatter: (name) => {
            if (piedata.length) {
              const item = piedata.filter((item) => item.name === name)[0]
              return `{name|${name}：}{value| ${item.value}}人`
            }
          }
        },
        series: [
          {
            name: '屈光异常率分析',
            type: 'pie',
            radius: ['40%', '52%'],
            center: ['50%', '50%'],
            label: {
              normal: {
                show: false,
                position: 'center',
                formatter: (name) => {
                  const value = parseFloat((name.value / normalData.haveScreenedNums * 100).toFixed(2)) || 0

                  return `{value|${value}%}\n{label|${name.name}}`
                },
                rich: {
                  value: {
                    padding: 5,
                    align: 'center',
                    verticalAlign: 'middle',
                    fontSize: 20,
                    fontWeight: 700
                  },
                  label: {
                    align: 'center',
                    verticalAlign: 'middle',
                    fontSize: 16
                  }
                }
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '12'
                }
              }
            },
            labelLine: {
              show: false,
              length: 0,
              length2: 0
            },
            data: piedata
          }
        ]
      }
      this.myChart3.setOption(option)
      this.getDefaultSelected(this.myChart3)
      this.myChart3.resize()
    },
    drawLine4 () {
      // 基于准备好的dom，初始化echarts实例
      const myChartId = document.getElementById('myChart4')

      this.myChart4 = this.$echarts.init(myChartId)
      const data = this.curveData
      const xAxis = []
      const seriesData = {
        abnormalRate: [],
        // Asersc: [],
        ametropiaRate: [],
        myopiaRate: []
      }
      data.forEach(item => {
        // console.log('item', item)
        xAxis.push(item.screeningName)
        seriesData.abnormalRate.push(item.abnormalRate.toFixed(0))
        // seriesData.Asersc.push(item.abnormalRate.toFixed(0) + '% ')
        seriesData.ametropiaRate.push(item.ametropiaRate.toFixed(0))
        seriesData.myopiaRate.push(item.myopiaRate.toFixed(0))
        // console.log('seriesData', seriesData.myopiaRate)
      })

      for (let i = 0; i < 2; i++) {
        const item = xAxis[i]
        if (!item) {
          xAxis.splice(i, 0, '')
        }
      }
      // let dataZoom = []
      // let bottom = 65
      // if (xAxis.length > 3) {
      //   bottom = 85
      //   dataZoom = [{
      //     show: true,
      //     xAxisIndex: [0],
      //     height: 12,
      //     moveHandleSize: 5,
      //     bottom: 40,
      //     start: 0,
      //     end: 100,
      //     handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',

      //     textStyle: {
      //       color: 'rgba(255,255,255,0)'
      //     }

      //   }, {
      //     type: 'inside',
      //     show: true,
      //     start: 1,
      //     end: 35
      //   }]
      // }
      const option = {
        tooltip: {
          trigger: 'axis',
          // axisPointer: {
          //   type: 'cross',
          //   label: {
          //     backgroundColor: '#6a7985'
          //   }
          // },
          textStyle: { align: 'left' },
          formatter: '{b}<br />{a0}: {c0}%<br />{a1}: {c1}%<br />{a2}: {c2}%'
        },
        title: {
          left: 'center',
          text: '视力健康曲线',
          top: '17px'
        },
        grid: {
          left: 50,
          borderWidth: 0,
          top: 50,
          bottom: 65,
          textStyle: {
            fontSize: 12,
            color: '#fff'
          }
        },
        xAxis: {
          type: 'category',
          data: xAxis,
          axisTick: {
            show: false // 隐藏x轴的分割点
          },
          axisLabel: {
            show: true,
            align: 'center',
            interval: 0,
            formatter: function (paramsName, index) {
              let newName = ''
              const arr = paramsName.split('')
              // console.log('paramsName', paramsName)
              for (let i = 0; i < arr.length; i++) {
                newName += arr[i]
                if (!(i % 4) && i !== arr.length && i !== 0) {
                  newName += '\n'
                }
              }

              return newName
            }
          }
        },
        yAxis: {
          type: 'value',
          min: 0,
          axisLine: { show: true },
          max: 100,
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value}%'
          }
        },
        legend: {
          data: ['近视率', '视力异常率', '屈光异常率'],
          left: 'center',
          bottom: 5
        },
        // dataZoom: dataZoom,
        series: [
          {
            name: '近视率',
            data: seriesData.myopiaRate,
            color: '#FFD012',
            type: 'line',
            label: {
              show: true,
              formatter: '{c}%'
            }
          },
          {
            name: '视力异常率',
            data: seriesData.abnormalRate,
            color: '#9DDF00',
            type: 'line'
            // label: {
            //   show: true,
            //   formatter: '{c}%'
            // }
          },
          {
            name: '屈光异常率',
            data: seriesData.ametropiaRate,
            type: 'line',
            color: '#4DA1FF'
            // label: {
            //   show: true,
            //   formatter: '{c}%'
            // }
          }
        ]
      }
      this.myChart4.clear()
      this.myChart4.setOption(option, true)
    },
    drawLine5 () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart5 = this.$echarts.init(document.getElementById('myChart5'))
      const data = this.degreeData
      this.myChart5.setOption({
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '60%',
            selectedMode: 'single',
            color: ['#FF0000', '#FA6401', '#FFD012'],
            label: {
              normal: {
                position: 'inner',
                formatter: '{d}%',
                color: '#fff',
                fontSize: '10px'
              }
            },
            data: [
              { value: data.severeMyopiaNums, name: '' },
              { value: data.moderateMyopiaNums, name: '' },
              { value: data.mildMyopiaNums, name: '', selected: true }
            ]
          }
        ]
      })
      this.myChart5.resize()
    },
    drawLine6 () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart6 = this.$echarts.init(document.getElementById('myChart6'))
      const data = this.degreeData
      this.myChart6.setOption({
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '60%',
            selectedMode: 'single',
            color: ['#FF0000', '#FA6401', '#FFD012'],
            label: {
              normal: {
                position: 'inner',
                formatter: '{d}%',
                color: '#fff',
                fontSize: '10px'
              }
            },
            data: [
              { value: data.severeMRefractiveNums, name: '' },
              { value: data.moderateRefractiveNums, name: '' },
              { value: data.mildRefractiveNums, name: '', selected: true }
            ]
          }
        ]
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.header-container{
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .route-name{
      line-height: 43px;
      font-size: 12px;
      color: #333;
    }
  }
}
.page{
  display: flex;
  flex-direction: column;
  padding: 0 25px 25px;
  background: #fff;
  text-align: center;
  min-width: 1000px;
  min-height: 100%;
  width: auto;
  .select{
    text-align: left;
    .el-input,.el-button,.el-select{
      margin-top: 5px;
      margin-bottom: 5px;
    }
    select{
      width: 208px;
      height: 33px;
      border-radius: 4px;
      border: 1px solid #CCCCCC;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      outline: none;
      margin-right:8px;
    }
    select:last-child{
      width: 250px;
    }
  }
  .echarts{
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
  }
  .first{
    width: 64%;
    flex: auto;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E9EFF4;
    margin-right: 5px;
    box-sizing: border-box;
    margin-top: 17px;
    .title{
      display: flex;
      margin: 0;
      .left,.right{
        display: flex;
        margin: 0 25px;
        margin-top: 13px;
        height: 47px;
        line-height: 47px;
        h3{
          font-size: 17px;
          font-weight: 500;
          color: #333333;
        }
        h2{
          font-size: 33px;
          margin-left: 17px;
          color: #4DAF5A;
        }
        h4{
          color: #4DAF5A;
          font-size: 33px;
          margin-left: 1px;
        }
      }
    }
    .echarts1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .myecharts1{
          width: 33%;
          position: relative;
          .Percent{
            position: absolute;
            width: 280px;
            top: 46%;
            font-size: 28px;
            font-weight: 500;
            color: #FFD012;
          }
          .Percent2{
            color: #9DDF00;
          }
          .Percent3{
            color: #4DA1FF;
          }
          .text{
            width: 280px;
            font-size: 13px;
            font-weight: 500;
            color: #333333;
          }
        }
      }

  }
  .second {
    width: 34%;
    min-width: 450px;
    background: #FFFFFF;
    border-radius: 4px;
    flex: auto;
    border: 1px solid #E9EFF4;
    box-sizing: border-box;
    margin-top: 17px;
  }
  .fourth{
    width: 52%;
    padding: 0 25px 25px;
    flex: auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 3px 0px rgba(239,242,247,1);
    border-radius: 4px;
    border: 1px solid #E9EFF4;
    margin: 17px 0 0px;
    text-align: center;
    margin-right: 5px;
    overflow: hidden;
    box-sizing: border-box;
    .fourthHeader{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    span{
      font-size: 10px;
      font-weight: 400;
      color: #4DAF5A;
    }
    .title{
      font-size: 17px;
      font-weight: 500;
      line-height: 57px;
      margin:0;
      color: #333333;
    }
    .fourth-tips{
      // margin-left: 141px;
      position: absolute;
      right: 0;
    }

  }
  .fifth{
    margin: 17px 0 0px;
    width: 45%;
    background: #FFFFFF;
    box-shadow: 0px 2px 3px 0px rgba(239,242,247,1);
    border-radius: 4px;
    display: flex;
    border: 1px solid #E9EFF4;
    box-sizing: border-box;
    .right,.left{
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title{
        font-size: 17px;
        font-weight: 500;
        margin: 25px 0;
        color: #333333;
      }
      ul{
        li{
          display: flex;
          align-items: center;
        }
        li:nth-child(1){
          font-size: 12px;
          font-weight: 500;
          color: #FFD012;
           div{
            width: 4px;
            height: 4px;
            background: #FFD012;
            margin-right: 3px;
            border-radius: 3px;
          }
        }
        li:nth-child(2){
          font-size: 12px;
          font-weight: 500;
          color: #FF8F00;
          margin: 7px 0px;
          div{
            width: 4px;
            height: 4px;
            margin-right: 3px;
            background: #FF8F00;
            border-radius: 3px;
          }
        }
        li:nth-child(3){
          font-size: 12px;
          font-weight: 500;
          color: #FF0000;
          div{
            width: 4px;
            height: 4px;
            background: #FF0000;
            border-radius: 3px;
            margin-right: 3px;
          }
        }
      }
    }
  }
}

</style>
